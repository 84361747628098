import { getParamsOptionsAsync } from '@/utils/common'

export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.picture-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        isSingle: true,
        disabled: true
      },
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        }),
      options: []
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'pictureUrl',
      type: 'imgUpload',
      label: 'file.welcome-picture',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
