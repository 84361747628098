import { basicRequiredRule } from '@/template/rule'
import { getParamsOptionsAsync } from '@/utils/common'

export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'file.picture-name'
    },
    {
      field: 'tenantId',
      type: 'treeSelect',
      label: 'file.tenant',
      isResolveGlobalParams: true,
      otherOptions: {
        isSingle: true
      },
      handler: () =>
        getParamsOptionsAsync('tenantList', {
          isNeedPreFix: false
        }),
      options: []
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        maxlength: 200
      }
    },
    {
      field: 'pictureUrl',
      type: 'imgUpload',
      label: 'file.welcome-picture',
      otherOptions: {
        uploadApiAction: 'system/uploadImgFile'
      }
    }
  ],
  validateRules: {
    name: [basicRequiredRule],
    pictureUrl: [basicRequiredRule]
  }
}
